const BASE_URL = '/'
const AUTH = '/auto-login'
const HOME = '/home'
const UNAUTHORIZED = '/unauthorized'

export default {
  BASE_URL,
  AUTH,
  HOME,
  UNAUTHORIZED,
}
