import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'
import Auth from '../views/auth/Auth.vue'

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    path: PATHS.HOME,
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.UNAUTHORIZED,
    name: 'Unauthorized',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '../views/unauthorized/Unauthorized.vue'
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  base: process.env.VUE_APP_BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth) {
    if (
      !store.getters.isLoggedInDashFinancial &&
      !localStorage.getItem('tokenDashFinancial')
    ) {
      window.location.href =
        'https://sistemasgruposn.com.br/sistemas/portal/financeiro'
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedInDashFinancial && to.meta.requiresAuth) {
    await store.dispatch('logout')
    window.location.href =
      'https://sistemasgruposn.com.br/sistemas/portal/financeiro'
  }
})

export default router
