<template>
  <div class=""></div>
</template>
<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'Auth',
  data() {
    return {
      isLoading: false,
      user: null,
      password: '',
    }
  },
  components: {},
  methods: {
    getQuery() {
      const { query } = this.$route
      this.user = {
        codUsuario: query.codUsuario,
        cpf: query.cpf,
        email: query.email,
        localPhoto: query.localPhoto,
        name: query.nome,
        permission: query.permission,
      }
      this.auth()
    },
    async auth() {
      try {
        await this.$store.dispatch('auth', this.user)
        this.$router.push(PATHS.HOME)
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getQuery()
  },
}
</script>
